<style>
.text {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  width: 500px;
  top: calc(50% - 100px);
  left: calc(50% - 250px);
}
.textMessage{
  font-size: 1.1rem;
}
</style>
<template>
  <div
        class=" d-flex flex-column align-items-center justify-content-center min-vh-100   pb-5 position-relative">
        <particles/>
        <div class="text" >
            <div class=" d-flex row justify-content-center align-content-center">
              <img src="https://helexiumfiles.com/img/HXWhite.png" alt="Logo" class="logo" style="z-index: 2 !important; width: 80%; height: 80%" />
                <h1 class="font-weight-bold text-foreground">
                    ¡No lo hemos encontrado!
                </h1>
                <p class="mt-4 mx-auto px-5 textMessage text-center" >
                    Lo sentimos, pero no hemos encontrado esta página en el sistema.
                    Por favor, contacta con el administrador para obtener la ruta adecuada.
                </p>
                <div class="mt-6 d-flex justify-content-center align-items-center">
                    <vs-button class="btn-lg" to="/dashboard" gradient>
                        Volver a la Página Principal</vs-button>
                </div>
            </div>
            
        </div>
    </div>
</template>

<script>
import particles from './particles.vue';


export default {
  components: {
      particles
  }
};

</script>
